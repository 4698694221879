.timer-wrapper {
  display: flex;
  justify-content: center;
  align-self: center;
}

.timer {
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: #aaa;
}

.time {
  font-size: 30px;
}
.too-late {
  font-size: 15px;
}

@media screen and (max-width: 600px) {
  .time {
    font-size: 20px ;
  }
  .too-late {
    font-size: 10px;
  }
  .text {
    font-size: 6px;
  }
}
