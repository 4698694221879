.content-horizontal-center {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.my-polls__search-input {
  width: 100%;
}

.content-fullscreen {
  min-height: calc(100vh - var(--nav-height));
}

.noresult {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.noresult--primary {
  font-size: 42px;
  font-weight: 500;
}
.noresult--secondary {
  font-size: 22px;
}

.noresult--cta,
.btn.noresult--cta {
  margin-top: 10px;
  font-size: 32px;
  padding: 2.2rem;
}

.user-polls .poll-wrapper {
  width: min(600px, 100vw);
  margin-bottom: 7rem;
}

.polls-header {
  min-width: 320px;
  max-width: min(600px, 100vw);
  width: min(600px, 100vw);
}

.my-polls__search-btn {
  background: transparent;
  border: none;
  outline: none;

  transition: all 0.1s;
  cursor: pointer;
}

.my-polls__search-btn .my-polls__search-btn--icon {
  padding: 4px 0;
  background: var(--gradient-primary--1);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  transition: all .4s;
}
.my-polls__search-btn:active {
  transform: translateY(1px);
}
.my-polls__search-btn .my-polls__search-btn--icon:focus,
.my-polls__search-btn .my-polls__search-btn--icon:active {
  outline: none;
  border: none;
}

.my-polls__search-btn:disabled .my-polls__search-btn--icon {
  background: var(--color-grey-light-1);
  -webkit-background-clip: text;
  background-clip: text;
  cursor: default;
}
.my-polls__search-btn:disabled {
  cursor: default;
}
