.poll-wrapper {
  min-width: 320px;
  max-width: min(600px, 100vw);
  transition: height 0.5s ease-in-out;
  padding: 0;
}

.poll-wrapper .poll-body .form-item {
  padding: 0 5rem;
  margin: 0;
}

.poll-wrapper .poll-body .form-item:not(:last-child) {
  margin-bottom: 1rem;
}

.poll-wrapper .poll-body .form-item:last-child {
  padding-bottom: 4rem;
}

.poll-wrapper .poll-actions {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* If I decide to have a different background color */
  /* background: #eaeaea; */
  /* background: #dedede; */
  /* border-radius: 10px; */

  padding-right: 2px;
  padding-left: 2px;

  top: 100%;
  left: 100%;
  /* transform: translate(-100%, -100%); */
  transform: translate(
    calc(-100% - var(--poll-tools-padding-x) - 10px),
    calc(-100% - var(--poll-tools-padding-y))
  );

  margin: 0;
  /* padding-bottom: var(--poll-tools-padding-y); */
  /* padding-right: var(--poll-tools-padding-x); */
}

.poll-wrapper .poll-actions .poll-action {
  cursor: pointer;
  font-size: 14px;
  padding: var(--poll-tools-action-padding-x) var(--poll-tools-action-padding-y);

  transition: color 0.3s, -webkit-filter 0.3s;
  -webkit-transition: color 0.3s, -webkit-filter 0.3s;
}

.poll-wrapper .poll-actions .poll-action:not(:first-child) {
  margin-left: 6px;
}

.poll-wrapper .poll-actions .poll-action.poll-action--passcode:hover {
  color: var(--color-tertiary-dark);
}
.poll-wrapper .poll-actions .poll-action.poll-action--edit:hover {
  color: var(--color-tertiary);
}
.poll-wrapper .poll-actions .poll-action.poll-action--delete:hover {
  color: var(--color-danger--1);
}
.poll-wrapper .poll-actions .poll-action:hover {
  color: var(--color-primary);
}

.poll-wrapper .poll-actions .poll-action:not(.poll-action--no-active-style):active {
  filter: brightness(85%);
}

.poll-total-votes {
  align-self: flex-start;
}

.poll-title {
  color: var(--color-blue-dark-1);
  font-size: 3.4rem;
  margin-right: auto;
}

.poll-description {
  font-size: 2.2rem;
  font-style: italic;
}

.poll-title > span {
  color: var(--color-primary);
}

.poll-title > span:hover {
  text-decoration: underline;
  cursor: pointer;
}

.poll-title > span::selection {
  background: var(--color-primary-light);
  color: white;
}

.poll-tags {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  /* flex-direction: column; */
}

.poll-detail-wrapper {
  position: relative;
  align-self: center;
  display: flex;
  width: 100%;
  padding: 2rem 4rem;
  background: var(--color-grey-light-1);
  border-radius: 10px 10px 0 0;
}
.poll-detail-wrapper > *:not(:first-child) {
  margin-left: 1rem;
}
.poll-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  -ms-word-break: break-all;
  word-break: break-all;

  /* Non standard for WebKit */
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;

  /* margin-left: 1rem;	 */
}

@media screen and (max-width: 600px) {
  .poll-title {
    color: var(--color-blue-dark-1);
    font-size: 2rem;
  }
  .poll-description {
    font-size: 1.4rem;
  }
}