.signup {
  height: calc(100vh - var(--nav-height));
  overflow: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signup > *,
.signup > * > *,
.signup > * > * > *,
.signup > * > * > * > *,
.signup > * > * > * > * > * {
  font-family: "Montserrat", sans-serif;
}

.signup-switch {
  width: 100%;
  text-align: right;
  padding-right: 0.9rem;
}

.signup-switch-action {
  text-decoration: none;
  color: var(--color-grey-dark-1);
  font-weight: 700;
}

.signup-switch-action:hover {
  color: var(--color-primary-light);
}
