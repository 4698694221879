.navbar {
  background: var(--nav-gradient);
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--nav-height);
  font-size: 2.2rem;
  padding: 0 var(--nav-x-padding);
  z-index: 10000;
  position: relative;
}

.navbar > *,
.navbar > * > *,
.navbar > * > * > *,
.navbar > * > * > * > * {
  font-family: "PT Sans", sans-serif;
  /* Disable text selection */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  /* No Drag */
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

/* 
  ---------------------------
  ----------- Logo ----------
  --------------------------- 
  */
.navbar-logo,
.navbar-logo-text {
  font-family: "billabongregular", "Billabong";
}
.navbar-logo {
  margin-right: auto;
  margin-top: 10px; /* Fix font margin */
  cursor: pointer;
  text-decoration: none;
  font-size: 4rem;
  color: var(--color-white);
}

.navbar-logo__icon {
  margin-left: 6px;
  font-size: 3rem;
}
.navbar-logo__icon--gradient {
  background: var(--gradient-primary--1);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  /* animation: gradient_rotation 1s infinite; */
}


/* .navbar-logo .navbar-logo-text--gradient {
  background: var(--gradient-primary--1);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
} */

.navbar-logo:hover .navbar-logo-text {
  font-family: "billabongregular", "Billabong";
  color: var(--color-primary-light);
}

/* 
  ---------------------------
  ---------- Items ----------
  --------------------------- 
  */
.nav-menu {
  /* display: flex;
      list-style: none;
      width: 40vw; */

  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 40vw;
  justify-content: end;
  margin-right: 2rem;

  /* text-align: center; */
}

.nav-item {
  display: flex;
  align-items: center;
  height: var(--nav-height);

  /* Height doesn't change */
  /* height: calc(var(--nav-height) - var(--nav-active-height));
  margin-bottom: -3px;
  border-bottom: var(--nav-active-height) solid transparent; */
  transition: border-width 0.1s ease-out;
  -webkit-transition: border-width 0.1s ease-out;
}

/* .nav-item:has(.nav-link.nav-active) { }*/
.nav-item.nav-item--active {
  border-bottom: var(--nav-active-height) solid var(--color-primary);
}

.nav-link {
  color: var(--color-white);
  text-decoration: none;
  transition: background-color 0.2s ease-out;
  -webkit-transition: background-color 0.2s ease-out;
  border-radius: 4px;
  padding: 0.5rem 1rem;
}

.nav-link:hover {
  background: var(--color-primary);
}

.nav-link:active {
  background: var(--color-primary-damp);
  background: #3bac60;
}

/* 
  ---------------------------
  -------- Hamburger --------
  --------------------------- 
  */
.navbar-expand {
  display: none;
}

.navbar-expand .navbar-expand__icon {
  position: relative;
}

.navbar-expand .navbar-expand__icon,
.navbar-expand .navbar-expand__icon::before,
.navbar-expand .navbar-expand__icon::after {
  width: 2rem;
  height: 2px;
  background-color: var(--color-white);
  display: inline-block;
  border-radius: 1px;
}

.navbar-expand .navbar-expand__icon::before,
.navbar-expand .navbar-expand__icon::after {
  content: "";
  position: absolute;
  left: 0;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

.navbar-expand .navbar-expand__icon::after {
  top: 0.8rem;
}

.navbar-expand .navbar-expand__icon::before {
  top: -0.8rem;
}

.navbar-expand.active .navbar-expand__icon {
  background-color: transparent;
}

.navbar-expand.active .navbar-expand__icon::after,
.navbar-expand.active .navbar-expand__icon::before {
  top: 0;
}

.navbar-expand.active .navbar-expand__icon::after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.navbar-expand.active .navbar-expand__icon::before {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

/* navigation__checkbox */
/* navigation__button */
/* navigation__icon */

.nav-menu--cool-effect {
  position: relative;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  background-color: var(--color-primary);
}

.nav-menu--cool-effect.active {
  transform: translateX(0);
  -webkit-transform: translateX(0);
  background-color: var(--color-secondary);
}

/* 
  ---------------------------
  -------- Mobile --------
  --------------------------- 
  */
@media screen and (min-width: 961px) {
  .mobile-only {
    display: none;
  }
}

@media screen and (max-width: 961px) {
  .nav-menu {
    display: none;
  }
}
@media screen and (max-width: 960px) {
  /* @media screen and (max-width: 60px) { */
  .nav-menu {
    z-index: 9999;
    background: var(--color-grey-dark-4);
    min-height: calc(100vh - var(--nav-height));
    width: 100vw;
    margin: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0;

    position: absolute;
    top: var(--nav-height);
    left: -100%;

    transition: transform 0.2s, -webkit-transform 0.2s;
    -webkit-transition: transform 0.2s, -webkit-transform 0.2s;
  }

  .nav-menu.active {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }

  .nav-item {
    width: 100%;
    justify-content: center;
    position: relative;
  }

  .nav-item.nav-item--active {
    border-bottom: 0;
  }

  .nav-item::before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    transform: translateX(calc(-100% + var(--nav-active-width)));
    -webkit-transform: translateX(calc(-100% + var(--nav-active-width)));
    transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    background: var(--gradient-primary--1);
  }

  .nav-item:not(.nav-item--active)::before {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }

  .nav-link {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }

  .nav-item:hover::before {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }

  .nav-link:hover {
    background-color: transparent;
  }

  .navbar-expand {
    cursor: pointer;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar__btn {
    display: none;
  }

  .fa-caret-down {
    display: none;
  }
}
