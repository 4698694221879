.poll__add-option {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  /* margin: 0.6rem; */
  margin: 0 0.6rem;
}

.form-item__input--hashtag {
  font-size: var(--form-input-size);
  color: var(--color-primary);
  font-weight: 600;
}

.form-item__input--hashtag:hover {
  text-decoration: underline;
  cursor: pointer;
}

.p-rel {
  position: relative;
}

.dragbar {
  position: absolute;
  top: 50%;
  left: -20px;
  font-size: 1.5rem;
  color: var(--color-grey-dark-1-unfocused);

  transition: color 0.2s ease-out;

  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.dragbar:hover,
.form-item-wrapper:hover .dragbar {
  color: var(--color-grey-dark-1);
}

.droppable {
  width: 100%;
  margin-left: -0.6rem;
}

.neg-margin {
  margin-top: -1rem;
}

.poll-created-qr {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.poll-created-description {
  text-align: center;
  padding: 4px;
}

.form-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.form-section.form-section--centered {
  justify-content: center;
}

.form-section-item:not(:first-child) {
  margin-left: 30px;
}

.poll-create-form {
  max-width: 940px;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.w-100 {
  width: 100%;
}

@media screen and (max-width: 960px) {
  .poll-create-form {
    width: 90%;
  }
  .rw-datetime-picker.rw-widget {
    padding-right: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .poll-create-form {
    width: 100%;
  }
}
