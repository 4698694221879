.chip {
  background: var(--color-input-border-3);
  color: var(--color-black);
  border-radius: 3px;
  list-style: none;
  margin-top: 3px;
  padding: 4px;
}

.chip:hover {
  color: var(--color-primary);
  text-decoration: underline;
  cursor: pointer;
}

.chip:not(:last-child) {
  margin-right: 5px;
}
