@font-face {
  font-family: "Billabong";
  src: url("/fonts/Billabong.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "billabongregular";
  src: url("https://cdn.rawgit.com/milktronics/beaglegr.am/master/public/fonts/billabong-webfont.eot");
  src: url("https://cdn.rawgit.com/milktronics/beaglegr.am/master/public/fonts/billabong-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.rawgit.com/milktronics/beaglegr.am/master/public/fonts/billabong-webfont.woff")
      format("woff"),
    url("https://cdn.rawgit.com/milktronics/beaglegr.am/master/public/fonts/billabong-webfont.ttf")
      format("truetype"),
    url("https://cdn.rawgit.com/milktronics/beaglegr.am/master/public/fonts/billabong-webfont.svg#billabongregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

:root {
  --form-input-size: 1.6rem;
  --form-input-size--clipboard: 1rem;
  --form-placeholder-size: 1.3rem;

  --color-primary: #55c57a;
  --color-primary-light: #7ed56f;
  --color-primary-damp: #3bac60;
  --color-primary-dark: #28b485;

  --color-secondary-light: #ffb900;
  --color-secondary-dark: #ff7730;
  --color-tertiary: #2998ff;
  --color-tertiary-light: #228be6;
  --color-tertiary-extra-light: #7ebbf0;
  --color-tertiary-dark: #1c7ed6;
  --color-tertiary-dark--1: #006ec9;
  --color-tertiary-dark--2: #3e7aeb;

  --color-purple-dark: #5643fa;

  --color-bg-calm-1: #f8f9fa;

  --color-grey-light-1: #f7f7f7;
  --color-grey-light-2: #eee;
  --color-grey-light-3: #e9e9e9;
  --color-grey-light-4: #e0e0e0;
  --color-input-border-1: #dee2e6;
  --color-input-border-2: #cfcfcf;
  --color-input-border-3: #ccc;
  --color-grey-dark-1-unfocused: rgb(153, 153, 153, 0.4);
  --color-grey-dark-1: #999;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #555;
  --color-grey-dark-4: #3f3f3f;
  --color-grey-dark-6: #6c757d;

  --color-grey-dark-5: #2f2f2f;
  --color-blue-dark-1: #495057;
  --color-white: #fff;
  --color-black: #000;
  --color-black--1: #1c1b1b;
  --color-black--2: #1a1717;

  --color-danger--1-unfocused: rgb(217, 83, 79, 0.4);
  --color-danger--1: #d9534f;

  --shadow-light-1: rgba(0, 0, 0, 0.22);

  --gradient-primary--1: linear-gradient(
    130deg,
    var(--color-primary-light) 0%,
    var(--color-primary-dark) 100%
  );
  --gradient-primary--2: linear-gradient(
    130deg,
    var(--color-primary) 0%,
    var(--color-primary-light) 60%,
    var(--color-primary-dark) 100%
  );

  --gradient-secondary--1: linear-gradient(
    130deg,
    var(--color-secondary-light) 0%,
    var(--color-secondary-dark) 100%
  );

  --nav-height: 80px;
  --nav-active-height: 4px;
  --nav-active-width: 4px;
  --nav-x-padding: 30px;
  --nav-gradient: linear-gradient(
    90deg,
    var(--color-black--1) 0%,
    var(--color-black--2) 100%
  );

  --default-border-radius: 10px;

  --poll-tools-padding-x: 10px;
  --poll-tools-padding-y: 5px;

  --poll-tools-action-padding-x: 4px;
  --poll-tools-action-padding-y: 2px;

  /* --gradient-secondary--2: linear-gradient(
      130deg,
      var(--color-secondary) 0%,
      var(--color-secondary-light) 60%,
      var(--color-secondary-dark) 100%
    ); */
}

@keyframes gradient_rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  /* font-family: "PT Sans", sans-serif; */
  font-family: "Montserrat", sans-serif;
}

/* Fix ugly buttons on iOS devices */
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 1;
}

/* Fix for Font Awesome */
.fas {
  font-family: "Font Awesome 5 Free" !important;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

.privacy-policy {
  padding: 1em;
}
.privacy-policy > * {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@media screen and (min-width: 961px) {
  .privacy-policy {
    padding: 5em;
  }

  .privacy-policy strong {
    margin-top: 1em;
  }

  .privacy-policy li,
  .privacy-policy li::marker {
    margin-left: 2em;
  }
  .privacy-policy > * {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.8em;
  }
}

._loading_overlay_content > div:first-child {
  width: auto;
  margin-bottom: 15px;
}
.RMM__close-button,
.RMM__close-button:hover {
  background: rgb(0, 0, 0) !important;
  margin-top: var(--nav-height);
}
.RMM__body,
.RMM__body.RMM__body--is-active {
  /* height: 100%; */
  /* height: calc(100vh - var(--nav-height)) !important; */
  /* max-height: calc(100vh - var(--nav-height)); */
  overflow: hidden !important;
}

@media screen and (max-width: 960px) {
  .RMM__body,
  .RMM__body.RMM__body--is-active {
    /* height: 100%; */
    /* height: calc(100vh - var(--nav-height)) !important; */
    overflow: auto !important;
  }
}

.modal-loader-overlay {
  border-radius: calc(var(--default-border-radius) - 0.5px);
}

.opacity-0 {
  opacity: 0 !important;
  transition: opacity 0.5s;
}

.RMM__placeholder {
  /* margin-top: var(--nav-height); */
  background: rgb(0, 0, 0);
  /* transition: background 4s ease-out; */
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  background-color: var(--color-grey-dark-4);
  background: var(--color-bg-calm-1);
  overflow: auto !important;
  overflow-y: overlay !important;
}

.Toastify__toast-container.Toastify__toast-container--bottom-left {
  bottom: 0;
}

.Toastify__toast-container.Toastify__toast-container--top-right,
.Toastify__toast-container.Toastify__toast-container--top-center,
.Toastify__toast-container.Toastify__toast-container--top-left {
  margin-top: var(--nav-height);
}

@media screen and (max-width: 960px) {
  .Toastify__toast-container.Toastify__toast-container--top-right,
  .Toastify__toast-container.Toastify__toast-container--top-center,
  .Toastify__toast-container.Toastify__toast-container--top-left {
    display: none;
  }
}

.app {
  position: relative;
  z-index: 4;
}
/* React widgets */
.rw-datepicker-label {
  padding-left: 0.6rem;
}

.rw-datetime-picker,
.rw-widget {
  font-size: 1.5rem !important;
  margin-right: -0.4rem;
  margin-left: 0.6rem;
}

.rw-widget-input,
.rw-input {
  height: 47px;
  width: 100%;
  border-radius: 4px;
  outline: none;
  font-size: var(--form-input-size);
  color: var(--color-blue-dark-1);
  font-family: "Montserrat", sans-serif;
}

.rw-widget-input::placeholder,
.rw-input::placeholder,
.rw-widget-input.rw-input::placeholder {
  font-size: var(--form-placeholder-size);
  font-weight: 400;
  color: var(--color-grey-dark-1);
  font-family: "Montserrat", sans-serif;
}

.rw-widget-container > .rw-input.rw-widget-input {
  height: 47px;
}

.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 1.8rem;
  text-decoration: none;
}

.btn.disabled,
.btn:disabled,
.btn.btn--tertiary.disabled,
.btn.btn--tertiary:disabled,
.btn--primary-outline.disabled,
.btn--primary-outline:disabled {
  background: var(--color-grey-dark-2);
  border-color: var(--color-grey-dark-2);
  color: var(--color-white);
  cursor: default;
}

.btn.btn--tertiary {
  color: var(--color-white);
  background-color: var(--color-tertiary);
  transition: all 0.2s ease-in;
}

.btn.btn--tertiary:not(.disabled):not(:disabled):hover {
  background: var(--color-tertiary-dark);
}
.btn.btn--tertiary:not(.disabled):not(:disabled):active {
  background: var(--color-tertiary-dark--1);
}
.btn.btn--danger {
  color: var(--color-white);
  background-color: var(--color-danger--1);
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
}

.btn.btn--danger:not(.disabled):not(:disabled):hover {
  filter: brightness(0.95);
}
.btn.btn--danger:not(.disabled):not(:disabled):active {
  filter: brightness(0.8);
}

.btn.btn--primary {
  color: var(--color-white);
  background: var(--color-primary-light);
  transition: background-color 0.2s ease-out;
  border-radius: 4px;
  padding: 0.5rem 1rem;
}
.btn.btn--primary:hover {
  background: var(--color-primary-light);
}
.btn.btn--primary:active {
  background: var(--color-primary);
}

.btn.btn--primary-1 {
  color: var(--color-white);
  background: #4bbe37;
  /* background: var(--color-primary-light); */
  transition: all 0.2s ease-out;
  border-radius: 20px;
  padding: 0.5rem 1rem;
}
.btn.btn--primary-1:hover {
  background: #59c945;
  /* filter: brightness(0.95); */
}
.btn.btn--primary-1:active {
  filter: brightness(0.9);
}

.btn.btn--primary-outline {
  color: var(--color-white);
  background-color: var(--color-primary);
  transition: all 0.3s ease-out;
  border: 2px solid transparent;
}

.btn.btn--primary-outline:not(.disabled):not(:disabled):hover {
  background-color: transparent;
  color: var(--color-white);
  border: 2px solid var(--color-primary);
}
.btn.btn--primary-outline:not(.disabled):not(:disabled):active {
  transform: scale(0.992);
  -webkit-transform: scale(0.992);
  /* transform: translateY(1px) scale(.99); */
}

/* 
  --------------------------
  ---------- Form ----------
  -------------------------- 
  */
.form-title {
  color: var(--color-blue-dark-1);
  font-size: 3.4rem;
  margin-bottom: 1rem;
  /* border-bottom: 1px solid var(--color-input-border-1); */
}

.form-item.form--mb1.form-item--no-margin,
.form-item.form--mb1,
.form--mb1 {
  margin-bottom: 1rem;
}
.form--mb0 {
  margin-bottom: 0;
}

.form-form-wrapper {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 20px 40px;
  border-radius: var(--default-border-radius);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px var(--shadow-light-1);
  /* box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px var(--color-grey-dark-3); */
  /* box-shadow: 0px 10px 50px var(--color-grey-dark-3); */
  background-color: var(--color-white);
}

.form-form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.form-centered-container {
  min-height: calc(100vh - var(--nav-height));
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Center nicer for mobile */
@media screen and (max-width: 960px) {
  /* with align-mobile class */
  .form-centered-container {
    /* margin-top: calc(-1 * var(--nav-height));
    height: 100vh; */
  }
}

.form-centered-container > *,
.form-centered-container > * > *,
.form-centered-container > * > * > *,
.form-centered-container > * > * > * > * {
  font-family: "Montserrat", sans-serif;
}

.form-description {
  text-align: left;
  width: 100%;
}

.form-switch {
  width: 100%;
  text-align: right;
  padding-right: 0.9rem;
}

.form-switch-action {
  text-decoration: none;
  font-style: italic;
  /* color: var(--color-grey-dark-1); */
  color: var(--color-primary-light);
  font-weight: 700;
  transition: color 0.1s;
}

.form-switch-action:hover,
.form-switch-action:active {
  color: var(--color-primary);
  cursor: pointer;
}

.form-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0.6rem;
}

.form-item.form-item--clipboard {
  width: 200px;
}

.form-item.form-item--no-margin {
  margin: 0;
}

.form-item--row {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.form-item .form-item__multiline-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.form-item .form-item__multiline-label .form-item__multiline-label-description {
  font-size: 1rem;
}

.form-item p,
.form-item label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-blue-dark-1);
}

.form-item label {
  font-size: 1.8rem;
  font-weight: 500;
}

.form-item label.optional::after,
.form-item p.optional::after {
  align-self: flex-end;
  margin-left: 5px;
  font-style: italic;
  content: "(optional)";
  font-size: 1rem;
  color: var(--color-secondary-dark);
}
.form-item label.required::after,
.form-item p.required::after {
  align-self: flex-end;
  margin-left: 5px;
  font-style: italic;
  content: "(required)";
  font-size: 1rem;
  color: var(--color-secondary-dark);
}
.form-item__submit {
  height: 5.5rem;
  margin-top: 1rem;
}
.form-item__submit.form-item__submit--center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-item__input {
  height: 47px;
  width: 100%;
  padding: 1rem;
  border-radius: 4px;
  outline: none;
  border: 2px solid var(--color-input-border-1);
  font-size: var(--form-input-size);
  color: var(--color-blue-dark-1);
  font-family: "Montserrat", sans-serif;
  margin-right: -3.25rem;
  padding-right: 3.4rem;
}

.form-item__input--small:disabled,
.form-item__input:disabled {
  background: var(--color-grey-light-1);
}

.form-item__input.form-item__input--clipboard {
  font-size: var(--form-input-size--clipboard);
  padding-right: 3.6rem;
  background: var(--color-grey-light-3);
}

.form-item__input--small {
  /* font-size: var(--form-input-size--small); */
  height: 43px;
}

.form-item__input:focus:not(.form-item__input--clipboard) {
  border-color: #4dabf7;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.0125),
    0 0 8px rgba(34, 139, 230, 0.5);
}

.form-item__input--textarea {
  min-height: 7rem;
  resize: vertical;
}

/* Font Awesome Icon inside input */
.form-item-wrapper .form-item__input[type="text"]:before {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-item .form-item__input::placeholder {
  font-size: var(--form-placeholder-size);
  font-weight: 400;
  color: var(--color-grey-dark-1);
}

.form-item__input-icon {
  font-size: 1.5rem;
  color: var(--color-grey-dark-1);
}
.form-item__input-icon.form-item__input-icon--clipboard {
  padding: 4px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.form-item__input-icon.form-item__input-icon--clipboard:hover {
  color: var(--color-primary);
}
.form-item__input-icon.form-item__input-icon--clipboard:active {
  filter: brightness(0.85);
}
.form-item__input-remove {
  font-size: 1.5rem;
  color: var(--color-danger--1-unfocused);
  transition: color 0.2s ease-out;
}
.form-item-wrapper:hover .form-item__input-remove {
  color: var(--color-danger--1);
}

.form-item__input-remove:hover {
  cursor: pointer;
}

.form-item .form-item__input.form-item__input--err {
  border: 1px solid var(--color-danger--1);
}

.form-item .form-item__error {
  color: var(--color-danger--1);
}

.form-item__error {
  color: var(--color-danger--1);
  font-size: 1.4rem;
}

/* 
  ---------------------------
  --------- Margins ---------
  --------------------------- 
  */
.mt-0 {
  margin-top: 0rem;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}
.mt-4 {
  margin-top: 4rem;
}
.mt-5 {
  margin-top: 5rem;
}
.mt-6 {
  margin-top: 6rem;
}

/* 
  ----------------------------
  --------- Paddings ---------
  ---------------------------- 
  */
.pt-0 {
  padding-top: 0rem;
}
.pt-1 {
  padding-top: 1rem;
}
.pt-2 {
  padding-top: 2rem;
}
.pt-3 {
  padding-top: 3rem;
}
.pt-4 {
  padding-top: 4rem;
}
.pt-5 {
  padding-top: 5rem;
}
.pt-6 {
  padding-top: 6rem;
}

/* 
  ----------------------------
  ---------- Mobile ----------
  ---------------------------- 
  */
@media screen and (min-width: 961px) {
  .mobile-only {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .desktop-only {
    display: none;
  }
}
